import React, { useState } from "react";
import LocationSearchInput from "./LocationSearchInput";
import "../styles/RegistrationForm.css";

function RegistrationForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    tripType: "",
    pickupDateTime: "",
    returnDateTime: "",
    vehicleType: "",
    note: "",
    numberOfPassengers: 0,
    infantSeat: 0,
    dropOffLocation: "",
    pickupLocation: "",
    carSeat: 0,
    luggages: 0,
    products: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.type === "number"
          ? parseInt(e.target.value, 10)
          : e.target.value,
    });
  };
  const handleLocationSelect = (place, id) => {
    setFormData({ ...formData, [id]: place.formatted_address });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("http://localhost:3000/submit-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        window.location.href = "#/home";
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit} className="row g-3">
        <h3>Customer Details</h3>
        <hr />
        <div className="col-md-6">
          <label htmlFor="fullName" className="form-label">
            Full Name
          </label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Full Name"
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </div>
        <div className="col-12">
          <label htmlFor="phoneNumber" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            className="form-control"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
          />
        </div>

        <hr />
        <h3>Trip Details</h3>
        <hr />
        <div className="col-6">
          <label htmlFor="tripType" className="form-label">
            Trip Type
          </label>
          <select
            className="form-select"
            id="tripType"
            name="tripType"
            value={formData.tripType}
            onChange={handleChange}
          >
            <option value="">Select Trip Type</option>
            <option value="One-way">One-way</option>
            <option value="Round-trip">Round-trip</option>
            <option value="One-way">Multi-destination</option>
            <option value="Round-trip">Hourly</option>
          </select>
        </div>
        <div className="col-6">
          <label htmlFor="vehcleType" className="form-label">
            Vehicle Type
          </label>
          <input
            type="text"
            className="form-control"
            id="vehicleType"
            name="vehicleType"
            value={formData.vehicleType}
            onChange={handleChange}
            placeholder="Vehicle Type"
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="pickupDateTime" className="form-label">
            Pick-up Location
          </label>
          <LocationSearchInput
            id="pickupLocation"
            placeholder="Enter pickup location"
            onPlaceSelected={handleLocationSelect}
          />
        </div>

        <div className="col-md-6">
          <label htmlFor="pickupDateTime" className="form-label">
            Pickup Date & Time
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="pickupDateTime"
            name="pickupDateTime"
            value={formData.pickupDateTime}
            onChange={handleChange}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="pickupDateTime" className="form-label">
            Drop-Off Location
          </label>
          <LocationSearchInput
            id="dropOffLocation"
            placeholder="Enter drop-off location"
            onPlaceSelected={handleLocationSelect}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="returnDateTime" className="form-label">
            Return Date & Time
          </label>
          <input
            type="datetime-local"
            className="form-control"
            id="returnDateTime"
            name="returnDateTime"
            value={formData.returnDateTime}
            onChange={handleChange}
          />
        </div>

        <div className="col-3">
          <label htmlFor="numberOfPassengers" className="form-label">
            Number of Passengers
          </label>
          <input
            type="number"
            className="form-control"
            id="numberOfPassengers"
            name="numberOfPassengers"
            value={formData.numberOfPassengers}
            onChange={handleChange}
            placeholder="0"
          />
        </div>

        <div className="col-3">
          <label htmlFor="infantSeat" className="form-label">
            Infant Seats
          </label>
          <input
            type="number"
            className="form-control"
            id="infantSeat"
            name="infantSeat"
            value={formData.infantSeat}
            onChange={handleChange}
            placeholder="0"
          />
        </div>

        <div className="col-3">
          <label htmlFor="carSeat" className="form-label">
            Car Seats
          </label>
          <input
            type="number"
            className="form-control"
            id="carSeat"
            name="carSeat"
            value={formData.carSeat}
            onChange={handleChange}
            placeholder="0"
          />
        </div>

        <div className="col-3">
          <label htmlFor="luggages" className="form-label">
            Number of Luggages
          </label>
          <input
            type="number"
            className="form-control"
            id="luggages"
            name="luggages"
            value={formData.luggages}
            onChange={handleChange}
            placeholder="0"
          />
        </div>

        <div className="col-12">
          <label htmlFor="note" className="form-label">
            Note
          </label>
          <textarea
            className="form-control"
            id="note"
            name="note"
            value={formData.note}
            onChange={handleChange}
            placeholder="Note"
            rows="3"
          ></textarea>
        </div>
        <div className="col-12" style={{ textAlign: "center" }}>
          <button type="submit" className="btn btn-primary">
            Proceed to Payment
          </button>
        </div>
      </form>
    </div>
  );
}

export default RegistrationForm;
