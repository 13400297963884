import React from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

function LocationSearchInput({ id, placeholder, onPlaceSelected }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBn9TWpmifwhK8m6o6_7M5GOo6BuGvfvn4",
    libraries,
  });

  const [autocomplete, setAutocomplete] = React.useState(null);

  const onLoad = (autocomplete) => {
    console.log("Autocomplete is loaded", autocomplete);
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      onPlaceSelected(autocomplete.getPlace(), id);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
      <input type="text" placeholder={placeholder} className="form-control" />
    </Autocomplete>
  );
}

export default LocationSearchInput;
