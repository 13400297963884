import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import PricingTable from "./components/PriceTbale.js";
//import JotForm from "./components/JotForm.js";
//import ReservationForm from "./components/ReservationForm.js";
import RegistrationForm from "./components/RegistrationForm.js";
import ContactForm from "./components/ContactForm.js";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Header className="sticky-header" />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/prices" element={<PricingTable />} />
            <Route path="/testimonials" element={<Testimonials />} />
            {/*<Route path="/jotform" element={<JotForm />} />
             <Route path="/reservationform" element={<ReservationForm />} />*/}
            <Route path="/registrationfrom" element={<RegistrationForm />} />
            <Route path="/contactform" element={<ContactForm />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
