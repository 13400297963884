import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { navLinks } from "./Data";
import logo from "../images/gator-logo.png";

function Header() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand href="/">
        <img
          src={logo}
          alt="Orlando Limo Services Logo"
          height="30"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {navLinks.map((link) => (
            <Nav.Link
              key={link.href}
              href={link.href}
              className="nav-item-custom"
            >
              {link.title}
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
