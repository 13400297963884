import airportImage from "../images/airports.webp";
import themeParkImage from "../images/disney.webp";
import conventionImage from "../images/conventions.webp";
import privateEventImage from "../images/private&night life.webp";
import weddingImage from "../images/weddings.webp";
import portImage from "../images/ports.jpeg";

export const navLinks = [
  { href: "#/home", title: "Home" },
  { href: "#/services", title: "Services" },
  /*{ href: "#/reservationform", title: "Booking" },
  { href: "#/jotform", title: "Contact Us" },*/
  { href: "#/Testimonials", title: "Testimonials" },
  { href: "#/prices", title: "Rates" },
  { href: "#/registrationfrom", title: "BOOK" },
  { href: "#/contactform", title: "Contact US" },
];

export const servicesData = [
  {
    id: 1,
    title: "Airports",
    description: "Reliable transportation to and from airports.",
    image: "airportImage",
  },
  {
    id: 2,
    title: "Theme Parks",
    description: "Enjoy a comfortable ride to your favorite theme park.",
    image: themeParkImage,
  },
  {
    id: 3,
    title: "Conventions",
    description: "Transportation services for conventions and events.",
    image: conventionImage,
  },
  {
    id: 4,
    title: "Private Events",
    description: "Luxurious transportation for private events and parties.",
    image: privateEventImage,
  },
  {
    id: 5,
    title: "Weddings",
    description: "Make your wedding day special with our limo services.",
    image: weddingImage,
  },
  {
    id: 6,
    title: "Ports",
    description: "Travel comfortably to and from ports for cruises.",
    image: portImage,
  },
];

export const contactInfo = {
  phone: "(123) 456-7890",
  email: "info@orlandolimo.com",
};

export const testimonials = [
  {
    text: "I am so happy and pleased with Gators Limo services! They were responsible, always responded to my every concern, question and suggested needs! Our driver Raja, phenomenal!! He dealt with teenager, toddlers and young adults...that's not easy but he did awesome! Very respectful, sharply dressed and handsome 🤣🤣 The limo was very clean and beautiful! Deff will use again!!!! Thank you Gators...you rock!!!",
    author: "Yolanda Villanueva Morales",
  },
  {
    text: "Me and my friends had prom last year and we got two sprinters from Gators Transportation. The cars were very clean and beautiful. Our drivers Sam and Oz were absolutely amazing. They were very professional and outgoing and made our prom experience amazing. Communication was great and both drivers were there on time. Make sure to book with Gators Transportation!",
    author: "Musa Suleiman",
  },
  {
    text: "Best luxury limo service in Orlando. We had a great experience during our stay at Disney world. Gators picked us up from Sanford airport to Grand Floridian and took us back at the end of our vacation, We have asked for a 10 passengers executive van, they sent us the Mercedes sprinter (free upgrade). Our driver Sam was very professional and knowledgeable. We will definitely use again When We come down here.",
    author: "Basam Ismail",
  },
  {
    text: "I am so happy and pleased with Gators Limo services! They were responsible, always responded to my every concern, question and suggested needs! Our driver Raja, phenomenal!! He dealt with teenager, toddlers and young adults...that's not easy but he did awesome! Very respectful, sharply dressed and handsome 🤣🤣 The limo was very clean and beautiful! Deff will use again!!!! Thank you Gators...you rock!!!",
    author: "Ytest2",
  },
  {
    text: "Me and my friends had prom last year and we got two sprinters from Gators Transportation. The cars were very clean and beautiful. Our drivers Sam and Oz were absolutely amazing. They were very professional and outgoing and made our prom experience amazing. Communication was great and both drivers were there on time. Make sure to book with Gators Transportation!",
    author: "Ytest3",
  },
  {
    text: "Best luxury limo service in Orlando. We had a great experience during our stay at Disney world. Gators picked us up from Sanford airport to Grand Floridian and took us back at the end of our vacation, We have asked for a 10 passengers executive van, they sent us the Mercedes sprinter (free upgrade). Our driver Sam was very professional and knowledgeable. We will definitely use again When We come down here.",
    author: "Ytest4",
  },
];
export const testimonialsar = [
  {
    text: "أنا سعيد جدًا ومسرور بخدمات Gators Limo! كانوا مسؤولين، ودائمًا ما يستجيبون لكل قلق وسؤال واحتياجات مقترحة! سائقنا راجا، استثنائي!! لقد تعامل مع المراهقين والأطفال الصغار والشباب... وهذا ليس بالأمر السهل لكنه كان رائعًا! كان محترمًا جدًا، وأنيق اللباس ووسيم 🤣🤣 كانت الليموزين نظيفة جدًا وجميلة! بالتأكيد سوف نستخدمها مرة أخرى!!!! شكرًا Gators... أنتم الأفضل!!!",
    author: "يولاندا فيلانويفا موراليس",
  },
  {
    text: "أنا وأصدقائي حضرنا حفلة موسيقية في العام الماضي وحصلنا على سبرينترز اثنين من Gators Transportation. كانت السيارات نظيفة جدًا وجميلة. كان سائقونا سام وأوز مذهلين تمامًا. كانوا محترفين جدًا ومنفتحين وجعلوا تجربة حفلتنا الموسيقية مذهلة. كان التواصل رائعًا وكان كلا السائقين في الموعد المحدد. تأكد من الحجز مع Gators Transportation!",
    author: "موسى سليمان",
  },
  {
    text: "أفضل خدمة ليموزين فاخرة في أورلاندو. كانت لدينا تجربة رائعة خلال إقامتنا في عالم ديزني. قامت Gators بالتقاطنا من مطار سانفورد إلى فندق Grand Floridian وأعادتنا في نهاية عطلتنا، لقد طلبنا فان تنفيذي يتسع لـ10 ركاب، أرسلوا لنا مرسيدس سبرينتر (ترقية مجانية). كان سائقنا سام محترفًا جدًا وعلى دراية. بالتأكيد سنستخدمها مرة أخرى عندما نأتي إلى هنا.",
    author: "بسام إسماعيل",
  },
];

export const testimonials22 = [
  {
    text2:
      " Gators Transportation saved my disneyworld vacation! Our rental car didnt work out and we thought we wouldn't be able to go to Disney. We called and got a ride an hour and a half later and we were able to to Magic Kingdom that day!We used their services several times throughout the week. they provided a carseat and a booster seat for my kids and we had Augusto as our driver each day. super friendly,  great communication and he drove safely. I cant wait until we go back to Orlando so we can use them again! thank you for saving our vacation!",
    author2: "Cosette Pfingst",
  },
  {
    text2:
      " Zack from gators transportation was amazing. We used him twice.  First was from Orlando airport to our hotel.  Second was hotel to dinner show and back at Capones. He stayed in export constant communication. SUV was beautiful and very clean.  He was dressed full suit and acted very professional.  He was very knowledgeable about the area.  His prices were perfect and very reasonable.  Will definitely call gators and request Zack on our next trip to Orlando.  Thanks again Zack!",
    author2: "Michael Rider",
  },
  {
    text2:
      " Stress free and amazing service. This was my 2nd time using this company while visiting Florida.Cars are clean and driver was very professionalBoth times we had Zack, he is exceptionally polite and goes out of his way to to make your ride a comfortable and most importantly Zack was 100% on time. I will not go back using another company while visiting Orlando Florida.Thanks for a great service Zack.",
    author2: "Sue Montazernezam",
  },
];

export const testimonials23 = [
  {
    text3:
      "My husband and I have used Larbi and Gator Transportation the last 3 times we have been to Orlando. To be honest, we won't ever be using anyone else. Larbi, is extremely professional and courteous. For our honeymoon, he waited for us outside our terminal and then escorted us to our port in a luxury ford SUV. It was a wonderful, smooth drive and extremely affordable. Thank you Gator Transportation for always providing excellent service!",
    author3: "Ashley Barbic",
  },
  {
    text3:
      "Gator transportation is the greatest. Zack and EZ are the most professional drivers I have ever had the pleasure to meet. They are both #1 in my book they made our vacation a 100 times more enjoyable. Thanks alot guys we will be back and will definitely use your service again.",
    author3: "Jason Hendershot",
  },
  {
    text3:
      "Thank you Gator Transportation & Larbi for getting my family to the airport on time. Your attentiveness to us & our belongings was much appreciated. I would definitely recommend.",
    author3: "Graham Cracker Childcare",
  },
];

export const testimonials22ar = [
  {
    text2:
      "أنقذت Gators Transportation عطلتي في ديزني وورلد! لم تنجح سيارتنا المستأجرة وظننا أننا لن نتمكن من الذهاب إلى ديزني. اتصلنا وحصلنا على رحلة بعد ساعة ونصف وتمكنا من الذهاب إلى مملكة السحر في ذلك اليوم! استخدمنا خدماتهم عدة مرات طوال الأسبوع. لقد وفروا لنا مقعد سيارة ومقعد معزز لأطفالي وكان لدينا أوغوستو كسائق لنا كل يوم. ودود للغاية، التواصل كان رائعاً وقاد بأمان. لا أطيق الانتظار حتى نعود إلى أورلاندو حتى نستخدمهم مرة أخرى! شكرًا لكم على إنقاذ عطلتنا!",
    author2: "كوزيت بفينغست",
  },
  {
    text2:
      "كان زاك من نقل Gators مذهلاً. استخدمناه مرتين. كانت الأولى من مطار أورلاندو إلى فندقنا. الثانية كانت من الفندق إلى عرض العشاء والعودة في كابونيز. ظل في تواصل مستمر. كانت السيارة SUV جميلة ونظيفة جدًا. كان يرتدي بدلة كاملة وتصرف بمهنية عالية. كان مطلعًا جدًا على المنطقة. أسعاره كانت مثالية ومعقولة جدًا. بالتأكيد سنتصل بـ Gators ونطلب زاك في رحلتنا القادمة إلى أورلاندو. شكرًا مرة أخرى زاك!",
    author2: "مايكل رايدر",
  },
  {
    text2:
      "خدمة مذهلة وبدون ضغوط. كانت هذه المرة الثانية التي أستخدم فيها هذه الشركة أثناء زيارة فلوريدا. السيارات نظيفة والسائق كان محترفًا جدًا. في كلا المرتين كان لدينا زاك، وهو مهذب بشكل استثنائي ويذهب إلى أبعد الحدود لجعل رحلتك مريحة والأهم من ذلك أن زاك كان دائمًا في الموعد 100٪. لن أعود لاستخدام شركة أخرى أثناء زيارة أورلاندو فلوريدا. شكرًا لك على خدمة رائعة زاك.",
    author2: "سو مونتازرنزام",
  },
];
export const testimonials23ar = [
  {
    text3:
      "أنا وزوجي استخدمنا خدمات لاربي و Gator Transportation في آخر ثلاث مرات كنا فيها في أورلاندو. لكي أكون صادقة، لن نستخدم أي شخص آخر أبدًا. لاربي، محترف للغاية ومهذب. خلال شهر عسلنا، انتظر لنا خارج المحطة ثم رافقنا إلى مينائنا في سيارة فورد SUV فاخرة. كانت رحلة رائعة وسلسة وبأسعار معقولة للغاية. شكرًا لكم Gator Transportation على تقديم خدمة ممتازة دائمًا!",
    author3: "آشلي باربيك",
  },
  {
    text3:
      "خدمة النقل من Gator هي الأفضل. زاك و EZ هما أكثر السائقين احترافًا اللذين كان لي الشرف في لقائهما. كلاهما رقم 1 في كتابي، جعلوا عطلتنا أكثر متعة بمئة مرة. شكرًا جزيلا يا رفاق، سنعود وبالتأكيد سنستخدم خدمتكم مرة أخرى.",
    author3: "جيسون هيندرشوت",
  },
  {
    text3:
      "شكرًا لكم Gator Transportation & لاربي على إيصال عائلتي إلى المطار في الوقت المحدد. كان تقديركم لنا ولمتعلقاتنا موضع تقدير كبير. بالتأكيد سأوصي بها.",
    author3: "رعاية أطفال جراهام كراكر",
  },
];
