import React, { useState, useMemo } from "react";
import { testimonials } from "./Data";
import "../styles/testo.css";

// Simple Memoized Modal Component
const Modal = React.memo(({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="btn btn-secondary close-btn">
          Close
        </button>
        {children}
      </div>
    </div>
  );
});

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const numPages = useMemo(
    () => Math.ceil(testimonials.length / 3),
    [testimonials.length]
  );

  const handlePrevClick = () => {
    if (!showModal) {
      setActiveIndex((prevIndex) => (prevIndex - 1 + numPages) % numPages);
    }
  };

  const handleNextClick = () => {
    if (!showModal) {
      setActiveIndex((prevIndex) => (prevIndex + 1) % numPages);
    }
  };

  const openModal = (testimonial) => {
    setSelectedTestimonial(testimonial);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  return (
    <div className="testimonials-container">
      <h2 className="testimonials-title">WHAT PEOPLE SAY</h2>
      <div className="carousel-container">
        <div className="carousel slide" id="testimonialCarousel">
          <div className="carousel-inner">
            {Array.from({ length: numPages }, (_, i) => (
              <div
                key={i}
                className={`carousel-item ${i === activeIndex ? "active" : ""}`}
              >
                <div className="d-flex justify-content-around">
                  {testimonials
                    .slice(i * 3, i * 3 + 3)
                    .map((testimonial, index) => (
                      <TestimonialCard
                        key={index}
                        testimonial={testimonial}
                        onShowMore={() => openModal(testimonial)}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
          <button
            className={`carousel-control-prev ${showModal ? "disabled" : ""}`}
            onClick={handlePrevClick}
            disabled={showModal}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className={`carousel-control-next ${showModal ? "disabled" : ""}`}
            onClick={handleNextClick}
            disabled={showModal}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <Modal show={showModal} onClose={closeModal}>
        <p>{selectedTestimonial?.text}</p>
        <footer className="blockquote-footer">
          {selectedTestimonial?.author}
        </footer>
      </Modal>
      <div className="jotform-button-container">
        <hr />
        <hr />

        <p className="text-center mb-3">
          We would love to hear from you! Please click below to add your
          testimonial.
        </p>
        <a href="#/contactform" className="contact-btn">
          Add your Testimonial
        </a>
      </div>
    </div>
  );
};

const TestimonialCard = React.memo(({ testimonial, onShowMore }) => {
  return (
    <div className="card" style={{ width: "18rem", marginBottom: "20px" }}>
      <div className="card-body">
        <p className="blockquote-footer">{testimonial.author}</p>
        <p className="card-text">{testimonial.text.substring(0, 100)}...</p>
        <button onClick={onShowMore} className="btn btn-primary">
          Show More
        </button>
      </div>
    </div>
  );
});

export default Testimonials;
