import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <div className="text-center">
        &copy; {new Date().getFullYear()} Gators Limo Services. All rights
        reserved.
      </div>
    </footer>
  );
}

export default Footer;
