import React from "react";
import "../styles/PricingTable.css"; // Ensure you have additional custom styles if needed

const PricingTable = () => {
  const services = [
    { service: "In Town Hourly rate (45 min)", prices: [90, 105, 125, 150] },
    {
      service: "MCO Airport to Magic Kingdom Hotels",
      prices: [125, 135, 195, 275],
    },
    { service: "MCO Airport to rest of Disney", prices: [115, 125, 180, 250] },
    { service: "MCO to Universal hotels", prices: [100, 110, 165, 250] },
    { service: "MCO to port canaveral Cruises", prices: [160, 195, 225, 425] },
    { service: "Sanford Airport to Disney", prices: [225, 250, 350, 425] },
    {
      service: "ISM Airport to Magic Kingdom Hotels",
      prices: [110, 125, 185, 250],
    },
    { service: "Kissimmee Airport to Disney", prices: [100, 110, 175, 250] },
    { service: "Orlando Executive to Disney", prices: [120, 135, 225, 325] },
  ];

  const vehicleTypes = ["Sedan", "SUV", "Sprinter", "Stretch Limo"];

  return (
    <div className="container mt-5">
      <table className="table table-bordered table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Service</th>
            {vehicleTypes.map((type, index) => (
              <th scope="col" key={index}>
                {type}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {services.map((item, index) => (
            <tr key={index}>
              <td>{item.service}</td>
              {item.prices.map((price, idx) => (
                <td key={idx}>${price}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center mt-4">
        <p>
          For other Destinations please call us on (407) 501-3333 or Fill in the
          form below
        </p>
        <a href="#/reservationform" className="btn btn-primary">
          Go to Reservation Form
        </a>
      </div>
    </div>
  );
};

export default PricingTable;
