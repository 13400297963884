import React, { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";

// Import images
import airportImage from "../images/airports.webp";
import themeParkImage from "../images/disney.webp";
import conventionImage from "../images/conventions.webp";
import privateEventImage from "../images/private.webp";
import weddingImage from "../images/weddings.webp";
import portImage from "../images/ports.jpeg";
import "../styles/service.css";

// Data for services
export const servicesData = [
  {
    id: 1,
    title: "Airports",
    description:
      "Arrive in Style and Comfort with Our Airport Chauffeur Services",
    images: [airportImage, airportImage, airportImage, airportImage], // Example: Repeat the same image for demo
  },
  {
    id: 2,
    title: "Theme Parks",
    description:
      "Enjoy a Stress-Free Theme Park Adventure with Our Chauffeur Services",
    images: [themeParkImage, themeParkImage, themeParkImage, themeParkImage], // Example: Repeat the same image for demo
  },
  {
    id: 3,
    title: "Conventions",
    description:
      "Elevate Your Convention Experience with Our Chauffeur Services",
    images: [conventionImage, conventionImage, themeParkImage, themeParkImage], // Example: Repeat the same image for demo
  },
  {
    id: 4,
    title: "Private Events",
    description:
      "Travel in Privacy and Comfort with Our Private Chauffeur Services",
    images: [
      privateEventImage,
      privateEventImage,
      themeParkImage,
      themeParkImage,
    ], // Example: Repeat the same image for demo
  },
  {
    id: 5,
    title: "Weddings",
    description:
      "Make Your Special Day Even More Memorable with Our Wedding Chauffeur Services",
    images: [weddingImage, weddingImage, themeParkImage, themeParkImage], // Example: Repeat the same image for demo
  },
  {
    id: 6,
    title: "Ports",
    description:
      "Sail into Your Next Adventure with Our Port Chauffeur Services",
    images: [portImage, portImage, themeParkImage, themeParkImage], // Example: Repeat the same image for demo
  },
];

function Services() {
  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (images) => {
    setSelectedImages(images);
    setShow(true);
  };

  return (
    <div className="container mt-5">
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {servicesData.map((service) => (
          <div key={service.id} className="col">
            <Card>
              <Card.Img
                variant="top"
                src={service.images[0]} // Display the first image for simplicity
                alt={service.title}
              />
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => handleShow(service.images)}
                >
                  SHOW MORE
                </Button>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImages.map((img, index) => (
            <img key={index} src={img} alt="Gallery" className="img-fluid" />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Services;
