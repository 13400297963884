import React from "react";
import { Carousel } from "react-bootstrap";
import "../styles/Home.css";
import slide1 from "../images/Main 1_edited.jpg";
import slide2 from "../images/Main 1_edited.jpg";
import slide3 from "../images/Main 1_edited.jpg";

function Home() {
  // Array of captions
  const captions = [
    "Book your ride with ease",
    "Enjoy a comfortable journey",
    "Reach your destination on time",
  ];

  return (
    <main>
      <div className="carousel-container">
        <Carousel>
          {/* Mapping through the images */}
          {[slide1, slide2, slide3].map((slide, index) => (
            <Carousel.Item key={index}>
              {/* Image */}
              <img
                className="carousel-image"
                src={slide}
                alt={`Slide ${index + 1}`}
              />
              {/* Caption */}
              <Carousel.Caption className="d-none d-md-block">
                {/* Accessing caption from captions array */}
                <h3>{captions[index]}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </main>
  );
}

export default Home;
